<template>
  <v-container>
    <v-btn text @click="$router.go(-1)" ><v-icon
        left
        dark
      >
        mdi-backburger
    </v-icon> Zurück zu Referenzen</v-btn>
    <ImageGallery v-model="galleryData"/>
  </v-container>
</template>
<script>
import ImageGallery from '@/components/ImageGallery.vue';

export default {
  name: 'balkon-mannheim',
  components: {
    ImageGallery,
  },
  data: () => ({
    galleryData: {
      imagePath: 'https://medo-sanierung.de/public_files/img/references/balkon-mannheim',
      imageCount: 8,
      imageFileFormat: '.jpg',
      name: 'Balkon Mannheim',
      description:
        'Nach dem Wanddurchbruch und dem Türeinbau folgte der Bau und die Montage eines Holzbalkones, inkl. Edelstahlgeländers.',
    },
    //
  }),
};
</script>
